/* globals $ */
jQuery = require('jquery')
slick = require('@accessible360/accessible-slick/slick/slick.js')
counterUp = require('counterup/jquery.counterup.min.js')
fitVids = require('fitvids.1.1.0')

function equalfirstheight(cn) {
  var first = jQuery(cn).eq(0).outerHeight();
  jQuery(cn).not(":first").height(first).show();
}

jQuery(function ($) {

  if (jQuery(".slick").length) {
    jQuery(".slick").slick({
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 5,
      dots: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }

  jQuery(".js-match-height").matchHeight();

  jQuery(".js-match-height-grid").matchHeight({ byRow: false });

  equalfirstheight(".js-equal-first-height");

  jQuery(".js-counterup").counterUp({
    delay: 100, // the delay time in ms
    time: 2500, // the speed time in ms
  });

  // focus on input via main search button
  jQuery(function () {
    jQuery(".searchtrigger").click(function (e) {
      e.preventDefault();
      setTimeout(function () {
        jQuery("#searchinput").focus();
      }, 0);
    });
    jQuery("#searchinput").keypress(function (e) {
      e.stopPropagation();
    });
  });

  // fitvids (resizes video embeds to fit container)
  jQuery(".container").fitVids();
});

jQuery(window).on("load resize", function () {
  equalfirstheight(".js-equal-first-height");
});
